export const getJapaneseDayOfWeek = (year: number, month: number, day: number) => {
  const date = new Date(year, month - 1, day);
  const dayOfWeek = date.getDay();

  const japaneseDays = ["日", "月", "火", "水", "木", "金", "土"];
  return japaneseDays[dayOfWeek];
};

export const formatBuildingShift = ({
  year,
  month,
  day,
  buildingName,
  startHour,
  startMinute,
  endHour,
  endMinute,
}: {
  year: number;
  month: number;
  day: number;
  buildingName: string;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}) => {
  return `${year}年${month}月${day}日（${getJapaneseDayOfWeek(year, month, day)}）${buildingName} / ${startHour}:${startMinute.toString().padStart(2, "0")} - ${endHour}:${endMinute.toString().padStart(2, "0")}`;
};
