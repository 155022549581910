import { setToken } from "@senciamatch/frontend/libs/auth";
import { trpc } from "@senciamatch/frontend/libs/trpc";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const Login = () => {
  const loginMutation = trpc.login.useMutation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  return (
    <div>
      <div className="flex flex-col gap-2 my-4 px-2">
        <label htmlFor="email" className="text-sm text-gray-500">
          メールアドレス
        </label>
        <input
          id="email"
          type="email"
          className="border border-gray-300 rounded-md p-2"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="flex flex-col gap-2 my-4 px-2">
        <label htmlFor="password" className="text-sm text-gray-500">
          パスワード
        </label>
        <input
          id="password"
          type="password"
          className="border border-gray-300 rounded-md p-2"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <p className="text-sm text-gray-500 text-center">パスワードを忘れた方はこちら</p>

      {error && <p className="text-sm text-red-500 text-center">{error}</p>}

      <button
        type="button"
        className="w-full bg-[#00AF31] text-white p-2 rounded-md mt-8"
        onClick={async (e) => {
          e.preventDefault();
          try {
            setProcessing(true);
            const result = await loginMutation.mutateAsync({ email, password });
            setToken(result.token);
            navigate(redirect || "/");
          } catch (error) {
            setError("ログインに失敗しました");
          } finally {
            setProcessing(false);
          }
        }}
        disabled={processing}
      >
        ログイン
      </button>
    </div>
  );
};
