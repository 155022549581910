import { z } from "zod";

export const staffSchema = z.object({
  id: z.string().brand("staffId"),
  name: z.string(),
  nameKana: z.string(),
  postalCode: z.string(),
  address: z.string(),
  isSocialInsurance: z.boolean(),
  canWorkAtNight: z.boolean().default(false),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
});

export type Staff = z.infer<typeof staffSchema>;

export type StaffInput = z.input<typeof staffSchema>;

export const newStaff = (input: StaffInput): Staff => staffSchema.parse(input);

export const createStaff = (input: StaffInput): Staff => newStaff(input);
