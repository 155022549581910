import { v7 as uuidv7 } from "uuid";
import { z } from "zod";

export const buildingShiftGroupSchema = z.object({
  id: z.string().brand("buildingShiftGroupId"),
  year: z.number(),
  month: z.number(),

  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
});

export const newBuildingShiftGroupId = (id: string) => buildingShiftGroupSchema.shape.id.parse(id);

export type BuildingShiftGroup = z.infer<typeof buildingShiftGroupSchema>;

export type BuildingShiftGroupInput = z.input<typeof buildingShiftGroupSchema>;

export const newBuildingShiftGroup = (input: BuildingShiftGroupInput): BuildingShiftGroup =>
  buildingShiftGroupSchema.parse(input);

export const createBuildingShiftGroup = (input: Omit<BuildingShiftGroupInput, "id">): BuildingShiftGroup =>
  newBuildingShiftGroup({ ...input, id: uuidv7() });
