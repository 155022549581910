import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { buildingSchema } from "./building";
import { buildingShiftSchema } from "./buildingShift";
import { staffSchema } from "./staff";
import { staffShiftSchema } from "./staffShift";
import { workShiftGroupSchema } from "./workShiftGroup";

export const workShiftSchema = z.object({
  id: z.string().brand("workShiftId"),
  workShiftGroupId: workShiftGroupSchema.shape.id,
  staffId: staffSchema.shape.id.nullish(),
  staffShiftId: staffShiftSchema.shape.id.nullish(),
  buildingId: buildingSchema.shape.id.nullish(),
  buildingShiftId: buildingShiftSchema.shape.id.nullish(),
  status: z.string().default("NotConfirmed"),
  isFirstShift: z.boolean().default(false),
  year: z.number(),
  month: z.number(),
  day: z.number(),
  startHour: z.number().nullish(),
  startMinute: z.number().nullish(),
  endHour: z.number().nullish(),
  endMinute: z.number().nullish(),
});

export type WorkShift = z.infer<typeof workShiftSchema>;

export type WorkShiftInput = z.input<typeof workShiftSchema>;

export const newWorkShift = (input: WorkShiftInput): WorkShift => workShiftSchema.parse(input);

export const createWorkShift = (input: Omit<WorkShiftInput, "id">): WorkShift =>
  newWorkShift({ ...input, id: uuidv7() });
