import { type ParseResult, parse } from "papaparse";

type CSVRow = string[];

const detectEncoding = (buffer: ArrayBuffer): string => {
  const encodings = ["utf-8", "shift-jis", "euc-jp", "iso-2022-jp"];
  for (const encoding of encodings) {
    try {
      const decoder = new TextDecoder(encoding, { fatal: true });
      decoder.decode(buffer);
      return encoding;
    } catch (e) {
      // If decoding fails, try the next encoding
    }
  }
  return "utf-8";
};

export const fileParser = (file: File): Promise<CSVRow[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const buffer = e.target?.result as ArrayBuffer;
      if (!buffer) {
        reject(new Error("Failed to read file"));
        return;
      }

      const detectedEncoding = detectEncoding(buffer);

      let decodedString: string;
      try {
        const decoder = new TextDecoder(detectedEncoding);
        decodedString = decoder.decode(buffer);
      } catch (error) {
        console.error("Decoding error:", error);
        reject(new Error("Failed to decode file"));
        return;
      }

      parse<CSVRow>(decodedString, {
        complete: (results: ParseResult<CSVRow>) => {
          resolve(results.data);
        },
        error: (error: Error) => {
          console.error("CSV parse error:", error);
          reject(new Error("CSV parse error"));
        },
      });
    };
    reader.onerror = (error: ProgressEvent<FileReader>) => {
      console.error("File read error:", error);
      reject(new Error("File read error"));
    };
    reader.readAsArrayBuffer(file);
  });
};
