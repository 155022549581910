import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { buildingShiftGroupSchema } from "./buildingShiftGroup";
import { staffShiftGroupSchema } from "./staffShiftGroup";

export const workShiftGroupSchema = z.object({
  id: z.string().brand("workShiftGroupId"),
  staffShiftGroupId: staffShiftGroupSchema.shape.id,
  buildingShiftGroupId: buildingShiftGroupSchema.shape.id,
  status: z.string().default("created"),
  name: z.string(),
  year: z.number(),
  month: z.number(),

  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
});

export const newWorkShiftGroupId = (id: string) => workShiftGroupSchema.shape.id.parse(id);

export type WorkShiftGroup = z.infer<typeof workShiftGroupSchema>;

export type WorkShiftGroupInput = z.input<typeof workShiftGroupSchema>;

export const newWorkShiftGroup = (input: WorkShiftGroupInput): WorkShiftGroup => workShiftGroupSchema.parse(input);

export const createWorkShiftGroup = (input: Omit<WorkShiftGroupInput, "id">): WorkShiftGroup =>
  newWorkShiftGroup({ ...input, id: uuidv7() });
