import { LoadingSpinner } from "./LoadingSpinner";

export const LoadingData = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center gap-4">
        <LoadingSpinner />
        <p className="text-xl font-bold mt-4">データを読み込み中です</p>
      </div>
    </div>
  );
};
