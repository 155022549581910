import { trpc } from "@senciamatch/frontend/libs/trpc";
import type { Staff } from "@senciamatch/shared/models/staff";
import { v7 as uuidv7 } from "uuid";

interface Props {
  staffs: Staff[];
  mutate: () => void;
}

export const StaffTable: React.FC<Props> = ({ staffs, mutate }) => {
  const updateStaff = trpc.updateStaff.useMutation();

  const updateStaffCanWorkAtNight = async (staffId: string, canWorkAtNight: boolean) => {
    await updateStaff.mutateAsync({ id: staffId, canWorkAtNight });
    mutate();
  };

  return (
    <div className="overflow-y-auto flex-grow">
      <table className="min-w-full">
        <thead className="sticky top-0 bg-gray-100">
          <tr className="bg-gray-100">
            <th className="p-2 text-left border border-gray-200">スタッフ番号</th>
            <th className="p-2 text-left border border-gray-200">氏名（漢字）</th>
            <th className="p-2 text-left border border-gray-200">氏名（カナ）</th>
            <th className="p-2 text-left border border-gray-200">全日勤務時深夜勤務可否</th>
            <th className="p-2 text-left border border-gray-200">郵便番号</th>
            <th className="p-2 text-left border border-gray-200">住所</th>
          </tr>
        </thead>
        <tbody>
          {staffs.map((staff) => (
            <tr key={uuidv7()}>
              <td className="p-2 text-left border border-gray-200">{staff.id}</td>
              <td className="p-2 text-left border border-gray-200">{staff.name}</td>
              <td className="p-2 text-left border border-gray-200">{staff.nameKana}</td>
              <td className="p-2 text-left border border-gray-200">
                {staff.canWorkAtNight ? "可" : "不可"}
                <button
                  type="button"
                  className="bg-primary-700 text-white px-8 py-1 rounded-md ml-2"
                  onClick={() => {
                    updateStaffCanWorkAtNight(staff.id, !staff.canWorkAtNight);
                  }}
                >
                  変更
                </button>
              </td>
              <td className="p-2 text-left border border-gray-200">{staff.postalCode}</td>
              <td className="p-2 text-left border border-gray-200">{staff.address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
