import { newStaff } from "@senciamatch/shared/models/staff";
import type { Staff } from "@senciamatch/shared/models/staff";

// CSV format
const CSV_FORMAT = [
  "ｽﾀｯﾌ番号", // 0
  "氏名(漢字)", // 1
  "氏名(ｶﾅ)", // 2
  "性別", // 3
  "郵便番号", // 4
  "住所1", // 5
  "住所2", // 6
  "住所3", // 7
  "NGクライアント番号", // 8
  "NG現場番号", // 9
  "午前開始時間", // 10
  "午前終了時間", // 11
  "全日開始時間", // 12
  "全日終了時間", // 13
  "深夜開始時間", // 14
  "深夜終了時間", // 15
  "固定物件", // 16
  "現場番号", // 17
  "クライアント番号", // 18
  "健康保険加入状況", // 19
];

export interface StaffStatus {
  [staffId: string]: "new" | "edited" | "persisted";
}

const rowStatus = (rowStaff: Staff, existingStaffs: Staff[]): StaffStatus => {
  const existingStaff = existingStaffs.find((staff) => staff.id === rowStaff.id);

  if (!existingStaff) return { [rowStaff.id]: "new" };

  if (existingStaff.name !== rowStaff.name) return { [rowStaff.id]: "edited" };
  if (existingStaff.nameKana !== rowStaff.nameKana) return { [rowStaff.id]: "edited" };
  if (existingStaff.postalCode !== rowStaff.postalCode) return { [rowStaff.id]: "edited" };
  if (existingStaff.address !== rowStaff.address) return { [rowStaff.id]: "edited" };
  if (existingStaff.isSocialInsurance !== rowStaff.isSocialInsurance) return { [rowStaff.id]: "edited" };

  return { [rowStaff.id]: "persisted" };
};

const validateCSV = (data: string[][]) => {
  const title = data[0];
  if (title.length !== CSV_FORMAT.length) return false;

  for (let i = 0; i < CSV_FORMAT.length; i++) {
    if (title[i] !== CSV_FORMAT[i]) return false;
  }

  return true;
};

const parse = async (data: string[][], existingStaffs: Staff[]): Promise<{ staffs: Staff[]; status: StaffStatus }> => {
  const status: StaffStatus = {};

  const staffs = data
    .slice(1)
    .map((row) => {
      const staffId = row[0];
      if (Number.isNaN(Number(staffId)) || staffId === "") return null;

      const rowStaff = newStaff({
        id: staffId,
        name: row[1],
        nameKana: row[2],
        postalCode: row[4],
        address: [row[5], row[6], row[7]].join(" "),
        isSocialInsurance: row[19] === "加入済",
      });

      status[staffId] = rowStatus(rowStaff, existingStaffs)[staffId];

      return rowStaff;
    })
    .filter((staff) => staff != null);

  return { staffs, status };
};

export const staffParser = {
  validateCSV,
  parse,
};
