import { z } from "zod";
import { buildingShiftSchema } from "./buildingShift";
import { buildingShiftGroupSchema } from "./buildingShiftGroup";

export const buildingShiftTableSchema = z.object({
  buildingShiftGroup: buildingShiftGroupSchema,
  rows: z.record(
    z.object({
      label: z.string(),
      cells: z.array(buildingShiftSchema.or(z.null())),
    }),
  ),
  errors: z.array(z.string()),
});

export type BuildingShiftTable = z.infer<typeof buildingShiftTableSchema>;

export type BuildingShiftTableInput = z.input<typeof buildingShiftTableSchema>;

export const newBuildingShiftTable = (input: BuildingShiftTableInput): BuildingShiftTable =>
  buildingShiftTableSchema.parse(input);

export const createBuildingShiftTable = (input: BuildingShiftTableInput): BuildingShiftTable =>
  newBuildingShiftTable(input);
