import { v7 as uuidv7 } from "uuid";
import { z } from "zod";

export const staffShiftGroupSchema = z.object({
  id: z.string().brand("staffShiftGroupId"),
  year: z.number(),
  month: z.number(),

  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
});

export const newStaffShiftGroupId = (id: string) => staffShiftGroupSchema.shape.id.parse(id);

export type StaffShiftGroup = z.infer<typeof staffShiftGroupSchema>;

export type StaffShiftGroupInput = z.input<typeof staffShiftGroupSchema>;

export const newStaffShiftGroup = (input: StaffShiftGroupInput): StaffShiftGroup => staffShiftGroupSchema.parse(input);

export const createStaffShiftGroup = (input: Omit<StaffShiftGroupInput, "id">): StaffShiftGroup =>
  newStaffShiftGroup({ ...input, id: uuidv7() });
