import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { companySchema } from "./company";

export const buildingSchema = z.object({
  id: z.string().brand("buildingId"),
  casNaviId: z.number(),
  name: z.string(),
  nameKana: z.string(),
  postalCode: z.string(),
  address: z.string(),
  companyId: companySchema.shape.id,
  isSuitsNeeded: z.boolean().default(false),
  mustCleaning: z.boolean().default(false),
  menOnly: z.boolean().default(false),
  womenOnly: z.boolean().default(false),
  createdAt: z.coerce.date().default(new Date()),
  updatedAt: z.coerce.date().default(new Date()),
});

export type Building = z.infer<typeof buildingSchema>;

export type BuildingInput = z.input<typeof buildingSchema>;

export const newBuilding = (input: BuildingInput): Building => buildingSchema.parse(input);

export const createBuilding = (input: Omit<BuildingInput, "id">): Building => newBuilding({ ...input, id: uuidv7() });
