import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { buildingSchema } from "./building";
import { buildingShiftSchema } from "./buildingShift";
import { staffSchema } from "./staff";
import { staffShiftSchema } from "./staffShift";
import { workShiftSchema } from "./workShift";
import { workShiftGroupSchema } from "./workShiftGroup";

export const workShiftCellSchema = z.object({
  workShiftId: workShiftSchema.shape.id,
  workShiftGroupId: workShiftGroupSchema.shape.id,
  staffShiftId: staffShiftSchema.shape.id.nullish(),
  buildingShiftId: buildingShiftSchema.shape.id.nullish(),
  buildingId: buildingSchema.shape.id.nullish(),
  buildingName: z.string().nullish(),
  staffId: staffSchema.shape.id.nullish(),
  staffName: z.string().nullish(),
  status: z.string(),
  year: z.number(),
  month: z.number(),
  day: z.number(),
  shiftSymbol: z.string().nullish(),
  isFirstShift: z.boolean(),
  startHour: z.number().nullish(),
  startMinute: z.number().nullish(),
  endHour: z.number().nullish(),
  endMinute: z.number().nullish(),
});

export type WorkShiftCell = z.infer<typeof workShiftCellSchema>;
export type WorkShiftCellInput = z.input<typeof workShiftCellSchema>;
export const newWorkShiftCell = (input: WorkShiftCellInput): WorkShiftCell => workShiftCellSchema.parse(input);
export const createWorkShiftCell = (input: Omit<WorkShiftCellInput, "workShiftId">): WorkShiftCell =>
  newWorkShiftCell({ ...input, workShiftId: uuidv7() });

export const workShiftCellSchemaByBuilding = workShiftCellSchema.extend({
  buildingShiftId: buildingShiftSchema.shape.id,
  buildingId: buildingSchema.shape.id,
  buildingName: z.string(),
});

export const workShiftCellSchemaByStaff = workShiftCellSchema.extend({
  staffShiftId: staffShiftSchema.shape.id,
  staffId: staffSchema.shape.id,
  staffName: z.string(),
  shiftSymbol: z.string(),
});

export type WorkShiftCellByBuilding = z.infer<typeof workShiftCellSchemaByBuilding>;
export type WorkShiftCellByBuildingInput = z.input<typeof workShiftCellSchemaByBuilding>;
export const newWorkShiftCellByBuilding = (input: WorkShiftCellByBuildingInput): WorkShiftCellByBuilding =>
  workShiftCellSchemaByBuilding.parse(input);

export const createWorkShiftCellByBuilding = (input: WorkShiftCellByBuildingInput): WorkShiftCellByBuilding =>
  newWorkShiftCellByBuilding(input);

export type WorkShiftCellByStaff = z.infer<typeof workShiftCellSchemaByStaff>;
export type WorkShiftCellByStaffInput = z.input<typeof workShiftCellSchemaByStaff>;

export const newWorkShiftCellByStaff = (input: WorkShiftCellByStaffInput): WorkShiftCellByStaff =>
  workShiftCellSchemaByStaff.parse(input);
export const createWorkShiftCellByStaff = (input: WorkShiftCellByStaffInput): WorkShiftCellByStaff =>
  newWorkShiftCellByStaff(input);
