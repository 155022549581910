import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { staffSchema } from "./staff";
import { staffShiftGroupSchema } from "./staffShiftGroup";

// 全日：Ａ、午前：Ｂ、深夜：Ｅ、未定：Ｇ、予約なし：―、勤務不可：×、全日（深夜可）：ＡＥ
export const SHIFT_SYMBOLS = ["○", "X", "-", "A", "B", "E", "G", "AE"];
export type ShiftSymbol = (typeof SHIFT_SYMBOLS)[number];

export const staffShiftSchema = z.object({
  id: z.string().brand("staffShiftId"),
  staffShiftGroupId: staffShiftGroupSchema.shape.id,
  staffId: staffSchema.shape.id,
  shiftSymbol: z.string(),
  year: z.number(),
  month: z.number(),
  day: z.number(),
});

export type StaffShift = z.infer<typeof staffShiftSchema>;

export type StaffShiftInput = z.input<typeof staffShiftSchema>;

export const newStaffShift = (input: StaffShiftInput): StaffShift => staffShiftSchema.parse(input);

export const createStaffShift = (input: Omit<StaffShiftInput, "id">): StaffShift =>
  newStaffShift({ ...input, id: uuidv7() });
