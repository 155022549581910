import { trpc } from "@senciamatch/frontend/libs/trpc";
import { Link, useNavigate } from "react-router-dom";
export const MatchingsPage: React.FC = () => {
  const navigate = useNavigate();

  const getWorkShiftGroups = trpc.getWorkShiftGroups.useQuery();

  if (getWorkShiftGroups.isLoading) return <div>Loading...</div>;
  if (!getWorkShiftGroups.data) return <div>No work shift groups found</div>;

  return (
    <div className="h-screen flex flex-col">
      <h2 className="text-2xl font-bold py-10 flex-shrink-0">シフト一覧</h2>
      <div className="overflow-x-auto py-10 bg-white border border-base-100 px-8 flex-grow flex flex-col">
        <div className="flex justify-between pb-8 flex-shrink-0">
          {/* <input type="text" className="w-1/2 border border-gray-200 rounded-md px-4 py-2" placeholder="検索" /> */}
          <button
            type="button"
            className="bg-primary-700 text-white px-4 py-2 rounded-md"
            onClick={() => navigate("/matchings/new")}
          >
            新規作成
          </button>
        </div>
        <div className="overflow-y-auto flex-grow">
          <table className="min-w-full">
            <thead className="sticky top-0 bg-gray-100">
              <tr className="bg-gray-100">
                <th className="p-2 text-left border border-gray-200">名前</th>
                <th className="p-2 text-left border border-gray-200">対象年月</th>
                <th className="p-2 text-left border border-gray-200">ステータス</th>
                <th className="p-2 text-left border border-gray-200">作成日</th>
                <th className="p-2 text-left border border-gray-200">操作</th>
              </tr>
            </thead>
            <tbody>
              {getWorkShiftGroups.data.workShiftGroups?.map((workShiftGroup) => (
                <tr key={workShiftGroup.id}>
                  <td className="p-2 text-left border border-gray-200">{workShiftGroup.name}</td>
                  <td className="p-2 text-left border border-gray-200">
                    {workShiftGroup.year}/{workShiftGroup.month}
                  </td>
                  <td className="p-2 text-left border border-gray-200">{workShiftGroup.status}</td>
                  <td className="p-2 text-left border border-gray-200">{workShiftGroup.createdAt.toLocaleString()}</td>
                  <td className="p-2 text-left border border-gray-200">
                    <Link to={`/matchings/${workShiftGroup.id}`} className="text-primary-700">
                      編集
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
