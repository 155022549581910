import { v7 as uuidv7 } from "uuid";
import { z } from "zod";
import { buildingSchema } from "./building";
import { buildingShiftGroupSchema } from "./buildingShiftGroup";

export const buildingShiftSchema = z.object({
  id: z.string().brand("buildingShiftId"),
  buildingShiftGroupId: buildingShiftGroupSchema.shape.id,
  buildingId: buildingSchema.shape.id,
  year: z.number(),
  month: z.number(),
  day: z.number(),
  startHour: z.number().nullable(),
  startMinute: z.number().nullable(),
  endHour: z.number().nullable(),
  endMinute: z.number().nullable(),
});

export type BuildingShift = z.infer<typeof buildingShiftSchema>;

export type BuildingShiftInput = z.input<typeof buildingShiftSchema>;

export const newBuildingShift = (input: BuildingShiftInput): BuildingShift => buildingShiftSchema.parse(input);

export const createBuildingShift = (input: Omit<BuildingShiftInput, "id">): BuildingShift =>
  newBuildingShift({ ...input, id: uuidv7() });
