import { LoadingSpinner } from "./LoadingSpinner";

export const FullScreenLoading = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center gap-4">
        <LoadingSpinner />
        <p className="text-gray-700 text-lg font-medium">Loading...</p>
      </div>
    </div>
  );
};
