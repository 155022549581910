import type { WorkShiftCell } from "@senciamatch/shared/models/workShiftCell";
import type { Mode } from "../../index";
interface Props {
  workShiftCell: WorkShiftCell | undefined;
  mode: Omit<Mode, "pickEdit">;
  setSelectedWorkShifts: (ids: Record<string, boolean>) => void;
  selectedWorkShifts: Record<string, boolean>;
}

const CellNull = () => {
  return (
    <td className="border border-gray-100 w-[270px] h-[80px]">
      <div className="flex items-center justify-center h-full w-[270px]">
        <div className="text-gray-500">-</div>
      </div>
    </td>
  );
};

export const Cell = (props: Props) => {
  const { workShiftCell, mode, setSelectedWorkShifts, selectedWorkShifts } = props;
  if (!workShiftCell) return <CellNull />;

  const handleClick = () => {
    if (mode === "view") return;

    if (selectedWorkShifts[workShiftCell.workShiftId]) {
      const newSelectedWorkShifts = { ...selectedWorkShifts };
      delete newSelectedWorkShifts[workShiftCell.workShiftId];
      setSelectedWorkShifts(newSelectedWorkShifts);
    } else {
      setSelectedWorkShifts({
        ...selectedWorkShifts,
        [workShiftCell.workShiftId]: true,
      });
    }
  };

  return (
    <td className="border border-gray-100 w-[270px] h-[80px]">
      <div
        className="flex flex-col items-center justify-center h-full w-[270px] relative"
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleClick();
        }}
      >
        <div className="absolute top-2 left-2">
          {mode === "pickEdit" &&
            (selectedWorkShifts[workShiftCell.workShiftId] ? (
              <img src="/icons/checked.svg" alt="checked" />
            ) : (
              <img src="/icons/not_checked.svg" alt="unchecked" />
            ))}
          {mode === "view" && (
            <>
              {workShiftCell.status === "Confirmed" && <img src="/icons/confirmed.svg" alt="confirmed" />}
              {workShiftCell.status === "NotConfirmed" && <img src="/icons/not_confirmed.svg" alt="not confirmed" />}
            </>
          )}
        </div>
        <p className="text-center">{workShiftCell.staffName}</p>
        <p className="text-center">
          {workShiftCell.shiftSymbol}({String(workShiftCell.startHour).padStart(2, "0")}:
          {String(workShiftCell.startMinute).padStart(2, "0")} - {String(workShiftCell.endHour).padStart(2, "0")}:
          {String(workShiftCell.endMinute).padStart(2, "0")})
        </p>
        {workShiftCell.isFirstShift && <p className="text-blue-500">初担当</p>}
      </div>
    </td>
  );
};
